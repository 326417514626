import { NextPage } from 'next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { MutableRefObject, useState, useRef } from 'react';
import { Segment, SegmentMenuItem, SyncRunDetails, Table } from '../../types/Common.interfaces';
import { Sync, SyncRun } from '../../types/Census.interfaces';
import { Button, IconButton, Typography, Box, Stack, Menu, MenuItem, Skeleton, Chip } from '@mui/material';
import { DataGridPro, GridColDef, GridRowModel, GridSortModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { useReportState } from '../../context/reportState';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import { format, parseISO, compareDesc, subDays } from 'date-fns';
import TableCheckboxFilter from '../tableCheckboxFilter/tableCheckboxFilter';
import SyncRunDetailsDrawer from '../syncRunDetailsDrawer/syncRunDetailsDrawer';
import EmptyData from '../emptyData/emptyData';
import databaseSvg from '../../public/images/database-icon.svg';

interface Props {
  rows: Segment[];
  loading: boolean;
  rowCount: number;
  autoHeight: boolean;
  apiRef: MutableRefObject<GridApiPro>;
  page: number;
  pageSize: number;
  handleSortChange: (model: GridSortModel) => void;
  handlePageChanged: (newPage: number) => void;
  handleChangePageSize: (pageSize: number) => void;
  handleFilterChange: (userId: string[]) => void;
  exportSegment: (segment: Segment) => void;
  cloneSegment: (segment: Segment) => void;
  deleteSegment: (segment: Segment) => void;
  seeAllButton?: boolean;
  title: string;
  hideFooter: boolean;
  selectedOptions: string[];
  skeletonRows?: number;
  loadingSegmentsCount: boolean;
  handleRefreshSegments?: () => void;
}

const SegmentsTable: NextPage<Props> = ({
  rows,
  loading,
  rowCount,
  autoHeight,
  apiRef,
  page,
  pageSize,
  handleSortChange,
  handlePageChanged,
  handleChangePageSize,
  handleFilterChange,
  exportSegment,
  cloneSegment,
  deleteSegment,
  seeAllButton,
  title,
  hideFooter,
  selectedOptions,
  skeletonRows = 15,
  loadingSegmentsCount,
  handleRefreshSegments,
}) => {
  const { numberFormatter, propertyFields, tables, capitalize, crmDestinationId, checkIsInitialSyncRunAfter } = useGlobalState();
  const { toolbarHeight } = useUiState();
  const {
    setSegmentSelected, setSelectedStatus, setSelectedTable, setDateRangeValue, setFromDate, setToDate
  } = useReportState();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [openSyndDetailDrawer, setOpenSyndDetailDrawer] = useState<boolean>(false);
  const [segmentTitle, setSegmentTitle] = useState<string>('');
  const [syncDetails, setSyncDetails] = useState<SyncRunDetails[]>([]);
  const [syncSetup, setSyncSetup] = useState<Sync[]>([]);
  const [syncEntity, setSyncEntity] = useState<string>('');
  const [lastSyncRun, setLastSyncRun] = useState<SyncRun | null>(null);
  const [lastInitialSegmentSyncRun, setLastInitialSegmentSyncRun] = useState<SyncRun | null>(null);
  const [segmentId, setSegmentId] = useState<string>('');
  const buttonRef = useRef<any>();
  const iconRef = useRef<any>();

  const handleCloseFilter = (event:  MouseEvent | TouchEvent | React.MouseEvent<HTMLElement>) => {
    if (event.target !== buttonRef.current && event.target !== iconRef.current) {
      setFilterAnchorEl(null);
    }
  };

  const handleToggleFilter = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
  };

  const renderSyncChip = (status: string) => {
    switch (status) {
      case 'completed':
        return (
          <Chip
            color='success'
            sx={{ borderRadius: '4px' }}
            label='Completed'
            variant='filled'
            size='small'
          />
        );
      case 'working':
        return (
          <Chip
            color='warning'
            sx={{ borderRadius: '4px' }}
            label='Working'
            variant='filled'
            size='small'
          />
        );
      case 'retrying':
        return (
          <Chip
            color='warning'
            sx={{ borderRadius: '4px' }}
            label='Working'
            variant='filled'
            size='small'
          />
        );
      case 'failed':
        return (
          <Chip
            color='error'
            sx={{ borderRadius: '4px' }}
            label='Failed'
            variant='filled'
            size='small'
          />
        );
      case 'skipped':
        return (
          <Chip
            color='primary'
            sx={{ borderRadius: '4px' }}
            label='Skipped'
            variant='filled'
            size='small'
          />
        );
      case 'queued':
        return (
          <Chip
            color='primary'
            sx={{ borderRadius: '4px', background: '#E6E1E5', color: 'rgba(0, 0, 0, 0.48);' }}
            label='Queued'
            variant='filled'
            size='small'
          />
        );
      default: <></>;
    }
  };

  const findLastSyncRunType = (syncs: Sync[]): string => {
    if (syncs.length > 0) {
      const type = syncs[0].schedule_frequency;
      return type === 'never' ? 'Manual' : 'Monthly';
    } else {
      return '';
    }
  };

  const lastSyncEntity = (syncs: Sync[], segmentId: string) => {
    const orgSyncs = syncs.filter((sync: Sync) => sync.label.toLowerCase().startsWith(`${segmentId}_org`));
    const peopleSyncs = syncs.filter((sync: Sync) => sync.label.toLowerCase().startsWith(`${segmentId}_per`));
    if (orgSyncs.length && peopleSyncs.length) {
      setSyncEntity('Organizations & Contacts');
    } else if (orgSyncs.length && !peopleSyncs.length) {
      setSyncEntity('Organizations');
    } else if (!orgSyncs.length && peopleSyncs.length) {
      setSyncEntity('Contacts');
    } else {
      setSyncEntity('');
    }
  };

  const findLastSyncRun = (syncs: Sync[], segmentSyncRuns: Sync[]): SyncRun | null => {
    const syncRuns: SyncRun[] = [];
    syncs.forEach((item: Sync) => {
      syncRuns.push(...item.syncRuns);
    });
    syncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
      aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
    ));
    const initialSegmentsSyncRuns: SyncRun[] = [];
    segmentSyncRuns.forEach((item: Sync) => {
      initialSegmentsSyncRuns.push(...item.syncRuns);
    });
    initialSegmentsSyncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
      aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
    ));
    const initialSyncAfter = checkSyncRuns(syncs, segmentSyncRuns);
    if (syncRuns.length && !initialSyncAfter) {
      return syncRuns[0];
    } else {
      if (initialSegmentsSyncRuns.length) {
        return initialSegmentsSyncRuns[0];
      } else {
        return null;
      }
    }
  };

  const prepareDetailsSyncData = (syncRuns: { label: string; orgSyncRun: SyncRun | null; peopleSyncRun: SyncRun | null }[]) => {
    let orgTotalFailed = 0;
    let orgTotalInvalid = 0;
    let perTotalFailed = 0;
    let perTotalInvalid = 0;
    let orgTotalSent = 0;
    let perTotalSent = 0;
    const details: SyncRunDetails[] = [];
    if (syncRuns.length) {
      details.push(
        {
          data_property: 'Created at',
          orgValue: syncRuns[0].orgSyncRun?.created_at,
          perValue: syncRuns[0].peopleSyncRun?.created_at,
          type: 'date',
          key: 'created_at',
        },
        {
          data_property: 'Updated At',
          orgValue: syncRuns[0].orgSyncRun?.updated_at,
          perValue: syncRuns[0].peopleSyncRun?.updated_at,
          type: 'date',
          key: 'updated_at',
        },
        {
          data_property: 'Completed At',
          orgValue: syncRuns[0].orgSyncRun?.completed_at,
          perValue: syncRuns[0].peopleSyncRun?.completed_at,
          type: 'date',
          key: 'completed_at',
        },
      );
    }
    syncRuns.forEach((syncRun: { label: string; orgSyncRun: SyncRun | null; peopleSyncRun: SyncRun | null }) => {
      if (syncRun.orgSyncRun) {
        const failedRecords = syncRun.orgSyncRun.records_failed || 0;
        const invalidRecords = syncRun.orgSyncRun.records_invalid || 0;
        const updatedRecords = syncRun.orgSyncRun.records_updated || 0;
        orgTotalFailed = orgTotalFailed + failedRecords;
        orgTotalInvalid = orgTotalInvalid + invalidRecords;
        orgTotalSent = orgTotalSent + updatedRecords + failedRecords + invalidRecords;
      }
      if (syncRun.peopleSyncRun) {
        const failedRecords = syncRun.peopleSyncRun.records_failed || 0;
        const invalidRecords = syncRun.peopleSyncRun.records_invalid || 0;
        const updatedRecords = syncRun.peopleSyncRun.records_updated || 0;
        perTotalFailed = perTotalFailed + failedRecords;
        perTotalInvalid = perTotalInvalid + invalidRecords;
        perTotalSent = perTotalSent + updatedRecords + failedRecords + invalidRecords;
      }
      details.push({
        data_property: capitalize(syncRun.label),
        orgValue: syncRun.orgSyncRun?.records_updated,
        perValue: syncRun.peopleSyncRun?.records_updated,
        type: 'link',
        key: 'status'
      });
    });
    details.push(
      {
        data_property: 'Records Sent',
        orgValue: orgTotalSent,
        perValue: perTotalSent,
        type: 'number',
        key: 'records_sent'
      },
      {
        data_property: 'Total Records Changed',
        orgValue: orgTotalSent,
        perValue: perTotalSent,
        type: 'number',
        key: 'records_updated'
      },
      {
        data_property: 'Records Invalid',
        orgValue: orgTotalInvalid,
        perValue: perTotalInvalid,
        type: 'number',
        key: 'records_invalid',
        // eslint-disable-next-line max-len
        description: 'Records are invalid when there is an error pulling from RevenueBase. Often times this is due to duplicates detected within the RevenueBase dataset.'
      },
      {
        data_property: 'Records Failed',
        orgValue: orgTotalFailed,
        perValue: perTotalFailed,
        type: 'number',
        key: 'records_failed',
        // eslint-disable-next-line max-len
        description: 'Records fail when there is an error pushing data into the CRM. Often times this is due to locked records or duplicates detected within the CRM.'
      },
    );
    details.sort((aValue: SyncRunDetails, bValue: SyncRunDetails) => {
      const firstValue =  propertyFields
        .find((value: { field: string, type: string, description?: string, order: number }) => value.field === aValue.key);
      const secondValue = propertyFields
        .find((value: { field: string, type: string, description?: string, order: number }) => value.field === bValue.key);
      if (firstValue && secondValue) {
        return firstValue.order - secondValue.order;
      } else {
        return -1;
      }
    });
    setSyncDetails(details);
  };

  const checkSyncRuns = (syncs: Sync[], initialSegmentSyncs: Sync[]) => {
    const reportSyncRuns: SyncRun[] = [];
    syncs.forEach((item: Sync) => {
      reportSyncRuns.push(...item.syncRuns);
    });
    reportSyncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
      aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
    ));
    const initialSegmentsSyncRuns: SyncRun[] = [];
    initialSegmentSyncs.forEach((item: Sync) => {
      initialSegmentsSyncRuns.push(...item.syncRuns);
    });
    initialSegmentsSyncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
      aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
    ));
    const initialSegmentSyncRun = initialSegmentsSyncRuns.length ? initialSegmentsSyncRuns[0] : null;
    const reportSyncRun = reportSyncRuns.length ? reportSyncRuns[0] : null;
    const initialSyncAfter = checkIsInitialSyncRunAfter(initialSegmentSyncRun, reportSyncRun);
    return initialSyncAfter;
  };

  const handleOpenSyncDetails = (syncs: Sync[], segmentId: string, initialSegmentSyncs: Sync[]) => {
    setOpenSyndDetailDrawer(true);
    if (syncs.length) {
      setSyncSetup(syncs);
    } else {
      setSyncSetup([]);
    }
    const initialSyncAfter = checkSyncRuns(syncs, initialSegmentSyncs);
    const selectedSyncRuns: SyncRun[] = [];
    const orgSyncs = syncs.filter((item: Sync) => item.label.toLowerCase().startsWith(`${segmentId}_org`));
    const syncRuns: { label: string; orgSyncRun: SyncRun | null; peopleSyncRun: SyncRun | null }[] = [];
    if (!initialSyncAfter) {
      if (orgSyncs.length) {
        orgSyncs.forEach((sync: Sync) => {
          const sortedSyncRuns = sync.syncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
            aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
          ));
          selectedSyncRuns.push(...sortedSyncRuns);
          const label = sync.label.toLowerCase().split(`${segmentId}_org`)[1];
          const values = label.split('_').filter((item: string) => item);
          if (sortedSyncRuns.length) {
            syncRuns.push({
              label: values.join(' '),
              orgSyncRun: sortedSyncRuns[0],
              peopleSyncRun: null,
            });
          }
        });
      }
      const peopleSyncs = syncs.filter((item: Sync) => item.label.toLowerCase().startsWith(`${segmentId}_per`));
      if (peopleSyncs.length) {
        peopleSyncs.forEach((sync: Sync) => {
          const sortedSyncRuns = sync.syncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
            aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
          ));
          selectedSyncRuns.push(...sortedSyncRuns);
          const label = sync.label.toLowerCase().split(`${segmentId}_per`)[1];
          const values = label.split('_').filter((item: string) => item);
          if (sortedSyncRuns.length) {
            const findSyncRun = syncRuns.find(
              (syncRun: { label: string; orgSyncRun: SyncRun | null; peopleSyncRun: SyncRun | null }) => syncRun.label === values.join(' ')
            );
            if (findSyncRun) {
              findSyncRun.peopleSyncRun = sortedSyncRuns[0];
            } else {
              syncRuns.push({
                label: values.join(' '),
                peopleSyncRun: sortedSyncRuns[0],
                orgSyncRun: null
              });
            }
          }
        });
      }
    } else {
      const findOrgSegmentSync = initialSegmentSyncs.find((sync: Sync) => sync.label.toLowerCase() === `${segmentId}_org_segment`);
      const findPerSegmentSync = initialSegmentSyncs.find((sync: Sync) => sync.label.toLowerCase() === `${segmentId}_per_segment`);
      if (findOrgSegmentSync) {
        const sortedOrgSyncRuns = findOrgSegmentSync.syncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
          aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
        ));
        if (sortedOrgSyncRuns.length) {
          syncRuns.push({
            label: 'Total',
            orgSyncRun: sortedOrgSyncRuns[0],
            peopleSyncRun: null,
          });
        }
      }
      if (findPerSegmentSync) {
        const sortedPerSyncRuns = findPerSegmentSync.syncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
          aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
        ));
        if (sortedPerSyncRuns.length) {
          const findSyncRun = syncRuns.find(
            (syncRun: { label: string; orgSyncRun: SyncRun | null; peopleSyncRun: SyncRun | null }) => syncRun.label === 'Total'
          );
          if (findSyncRun) {
            findSyncRun.peopleSyncRun = sortedPerSyncRuns[0];
          } else {
            syncRuns.push({
              label: 'Total',
              peopleSyncRun: sortedPerSyncRuns[0],
              orgSyncRun: null
            });
          }
        }
      }
    }
    if (selectedSyncRuns.length) {
      selectedSyncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
        aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
      ));
      setLastSyncRun(selectedSyncRuns[0]);
    } else {
      setLastSyncRun(null);
    }
    prepareDetailsSyncData(syncRuns);
  };

  const findLastSegmentSyncRun = (segmentSyncs: Sync[]) => {
    const syncRuns: SyncRun[] = [];
    segmentSyncs.forEach((sync: Sync) => {
      syncRuns.push(...sync.syncRuns);
    });
    if (syncRuns.length) {
      syncRuns.sort((aSyncRun: SyncRun, bSyncRun: SyncRun) => (
        aSyncRun.created_at && bSyncRun.created_at ? compareDesc(new Date(aSyncRun.created_at), new Date(bSyncRun.created_at)) : -1
      ));
      setLastInitialSegmentSyncRun(syncRuns[0]);
    } else {
      setLastInitialSegmentSyncRun(null);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Segment Name',
      flex: 1,
      minWidth: 190,
      cellClassName: 'text-highlight',
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{ cursor: 'pointer'  }}
          onClick={() => router.push(`/segments/${params.row.id}?tab=organizations`)}
        >
          {params.value}
        </Box>
      ),
      pinnable: false,
    },
    {
      field: 'author',
      headerName: 'Created by',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.author.firstName} {params.row.author.lastName}
        </>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created date',
      width: 140,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <>
          {format(parseISO(params.value), 'MM/dd/yyyy')}
        </>
      ),
      pinnable: false,
    },
    {
      field: 'companyCount',
      headerName: '# of Organizations',
      sortable: false,
      width: 210,
      disableColumnMenu: true,
      cellClassName: 'text-highlight',
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        loadingSegmentsCount ? (
          <Skeleton width='100%' height={40} sx={{ bgcolor: '#F5EEFA', zIndex: '1' }} />
        ) : (
          <Box
            sx={{ cursor: 'pointer'  }}
            onClick={() => router.push(`/segments/${params.row.id}?tab=organizations`)}
          >
            {numberFormatter(params.value)}
          </Box>
        )
      ),
      pinnable: false,
    },
    {
      field: 'peopleCount',
      headerName: '# of People',
      sortable: false,
      width: 210,
      disableColumnMenu: true,
      cellClassName: 'text-highlight',
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        loadingSegmentsCount ? (
          <Skeleton width='100%' height={40} sx={{ bgcolor: '#F5EEFA', zIndex: '1' }} />
        ) : (
          <Box
            sx={{ cursor: 'pointer'  }}
            onClick={() => router.push(`/segments/${params.row.id}?tab=people`)}
          >
            {numberFormatter(params.value)}
          </Box>
        )
      ),
      pinnable: false,
    },
    {
      field: 'updatedAt',
      headerName: 'CRM Sync',
      sortable: false,
      width: 210,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <>
          {findLastSyncRunType(params.row.syncs)}
        </>
      ),
      pinnable: false,
    },
    {
      field: 'syncs',
      headerName: 'Last CRM Sync',
      sortable: false,
      width: 400,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.segmentSyncs) {
          return <></>;
        }
        const syncRun = findLastSyncRun(params.value, params.row.segmentSyncs);

        return (
          syncRun ? (
            <Box className='u-flex u-flex-align-center u-flex-space-between u-w-100'>
              <Box className='u-flex u-flex-space-between u-flex-align-center u-pl-12' sx={{ flexBasis: '66%' }}>
                <Box component='span' className='u-pr-12'>
                  {syncRun.created_at ? format(parseISO(syncRun.created_at), 'MM/dd/yyyy') : ''}
                </Box>
                {renderSyncChip(syncRun.status)}
              </Box>
              {syncRun.status === 'completed' || syncRun.status === 'failed' ? (
                <Box className='u-ml-20 u-flex-33'>
                  <Button
                    className='u-text-transform-none'
                    onClick={() => {
                      setSegmentId(params.row.id);
                      findLastSegmentSyncRun(params.row.segmentSyncs);
                      lastSyncEntity(params.value, params.row.id);
                      setSegmentTitle(params.row.displayName);
                      handleOpenSyncDetails(params.value, params.row.id, params.row.segmentSyncs);
                    }}
                  >
                    Sync Details
                  </Button>
                </Box>
              ) : null}
            </Box>
          ) : <></>
        );
      },
      pinnable: false,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 160,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <Box className='actions-wrapper icons'>
          <IconButton
            onClick={() => {
              router.push(`/segments/${params.row.id}`);
            }}
          >
            <EditOutlinedIcon className='table-body-text-color' />
          </IconButton>
          <IconButton
            onClick={() => deleteSegment(params.row)}
          >
            <DeleteOutlinedIcon className='table-body-text-color' />
          </IconButton>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setAnchorEl(event.target);
              setSelectedRowId(params.row.id);
            }}
          >
            <MoreVertOutlinedIcon className='table-body-text-color' />
          </IconButton>
          <Menu
            onClose={() => {
              setAnchorEl(null);
              setSelectedRowId(null);
            }}
            elevation={0}
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl && selectedRowId === params.row.id}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PopoverClasses={{
              paper: 'table-menu u-br-4-px'
            }}
            autoFocus={false}
          >
            {segmentsMenuItems.map((item: SegmentMenuItem<Segment>, index: number) => (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setSelectedRowId(null);
                  item.onClick(params.row);
                }}
                key={index}
                className='table-menu-item fs-body-small'
              >
                {item.text}
              </MenuItem>
            ))}
            <Box className='table-menu-close-icon'>
              <IconButton
                onClick={() => {
                  setAnchorEl(null);
                  setSelectedRowId(null);
                }}
              >
                <CloseRoundedIcon sx={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          </Menu>
        </Box>
      ),
      pinnable: false,
      headerClassName: 'table-header hide-right-separator',
      resizable: false,
    }
  ];
  const [segmentsMenuItems] = useState<SegmentMenuItem<Segment>[]>(
    [
      {
        text: 'Open / Edit segment',
        onClick: (segment: Segment) => router.push(`/segments/${segment.id}`)
      },
      {
        text: 'Rename segment',
        onClick: (segment: Segment) => router.push(`/segments/${segment.id}?edit=true`)
      },
      {
        text: 'Export segment / CSV',
        onClick: (segment: Segment) => exportSegment(segment)
      },
      {
        text: 'Clone segment',
        onClick: (segment: Segment) => cloneSegment(segment)
      },
      {
        text: 'Delete segment',
        onClick: (segment: Segment) => deleteSegment(segment)
      }
    ]
  );
  const router = useRouter();

  const handleNewButtonClick = () => {
    router.push('/segments/new');
  };

  const handleViewReport = (params: GridRenderCellParams) => {
    setSelectedStatus([params.row.data_property.toLowerCase()]);
    setSegmentSelected(segmentId);
    setDateRangeValue('Custom');
    if (params.field === 'orgValue') {
      const findTable = tables.find((table: Table) => table.name.toLowerCase() === 'company');
      setSelectedTable(findTable ? findTable.id : '');
    } else if (params.field === 'perValue') {
      const findTable = tables.find((table: Table) => table.name.toLowerCase() === 'tam');
      setSelectedTable(findTable ? findTable.id : '');
    }
    if (lastSyncRun) {
      setToDate(new Date(lastSyncRun.created_at));
      const oneMonthAgo = subDays(new Date(lastSyncRun.created_at), 30);
      setFromDate(oneMonthAgo);
    }
    router.push('/reports/new');
  };

  return (
    <Box
      className='u-flex u-flex-direction-column'
      sx = {
        !autoHeight ? (
          { minHeight: `calc(100vh - ${toolbarHeight}px - 63px)`, height: '630px'}
        ) : (
          { minHeight: '345px' }
        )}
    >
      <Box className='table-top-wrapper u-flex u-flex-space-between u-flex-align-center u-p-16'>
        <Typography className='table-top-content fs-title-medium'>{title}</Typography>
        <Box>
          {seeAllButton ? (
            <Button
              className='u-mr-16 u-text-transform-none'
              variant='text'
              onClick={()=> router.push(`/segments?page=1&page-size=${rowCount}`)}
            >
              See All
            </Button>
          ) : null}
          <Button
            startIcon={<AddOutlinedIcon />}
            className='details-button u-mr-16'
            onClick={handleNewButtonClick}
          >
            New Segment
          </Button>
          {crmDestinationId ? (
            <Button
              startIcon={<CachedIcon />}
              className='details-button u-mr-16'
              onClick={() => handleRefreshSegments && handleRefreshSegments()}
            >
              Refresh Table
            </Button>
          ) : null}
          <IconButton
            ref={buttonRef}
            onClick={(event: React.MouseEvent<HTMLElement>) => handleToggleFilter(event)}
          >
            <TuneOutlinedIcon
              className='table-top-content-icon'
              ref={iconRef}
            />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        columns={columns}
        rows={rows}
        page={page-1}
        loading={loading}
        pageSize={pageSize}
        rowCount={rowCount}
        sx={{
          borderRadius: '0',
          backgroundColor: '#fff',
        }}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        autoHeight={autoHeight}
        headerHeight={48}
        rowHeight={48}
        paginationMode='server'
        sortingMode='server'
        sortingOrder={['asc', 'desc']}
        className='table'
        hideFooter={hideFooter}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        getRowId={(row: GridRowModel) => row.id}
        onSortModelChange={(model: GridSortModel) => handleSortChange(model)}
        apiRef={apiRef}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        onPageChange={(page) => handlePageChanged(page+1)}
        onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
        components={{
          NoRowsOverlay: () => <EmptyData text='Create a segment and it will appear here.' />,
          NoResultsOverlay: () => <EmptyData text='Create a segment and it will appear here.' />,
          LoadingOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              {Array.from(Array(skeletonRows).keys()).map((value: number) => (
                <Skeleton
                  key={value + 1}
                  variant='rectangular'
                  animation='wave'
                  width='100%'
                  height={36}
                  className='u-my-6'
                  sx={{ bgcolor: '#F5EEFA', zIndex: '1' }}
                />
              ))}
            </Stack>
          ),
          ErrorOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              <Box
                className='u-flex u-flex-justify-center u-flex-align-center u-w-64-px u-h-64-px u-br-50'
                sx={{ background: '#D9D9D9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
              >
                <Image
                  src={databaseSvg}
                  alt='Database icon'
                />
              </Box>
              <Typography className='fs-title-large u-fw-700'>Loading Error</Typography>
              <Typography className='fs-body-regular'>Oops, sorry, we seem to be having an issue on our end.</Typography>
            </Stack>
          )
        }}
      />
      <TableCheckboxFilter
        anchorEl={filterAnchorEl}
        handleClose={handleCloseFilter}
        handleFilterChange={handleFilterChange}
        title='Created By'
        selectedOptions={selectedOptions}
        filterEntity='segments'
      />
      <SyncRunDetailsDrawer
        open={openSyndDetailDrawer}
        setOpen={setOpenSyndDetailDrawer}
        segmentTitle={segmentTitle}
        syncDetails={syncDetails}
        syncs={syncSetup}
        syncEntity={syncEntity}
        handleViewReport={handleViewReport}
        lastSyncRun={lastSyncRun}
        lastInitialSegmentSyncRun={lastInitialSegmentSyncRun}
      />
    </Box>
  );
};

export default SegmentsTable;
