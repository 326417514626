import type { NextPage } from 'next';
import Image from 'next/image';
import { Box, Typography, Stack, Card } from '@mui/material';
import databaseSvg from '../../public/images/database-icon.svg';
import chartSvg from '../../public/images/chart-icon.svg';

interface Props {
  databaseError: boolean;
  classes: string;
}

const LoadingError: NextPage<Props> = ({ databaseError, classes }) => {
  return (
    <Card
      className={`u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-br-12-px ${classes}`}
      elevation={2}
    >
      <Stack height='100%' alignItems='center' justifyContent='center' className='u-w-100'>
        <Box
          className='u-flex u-flex-justify-center u-flex-align-center u-w-64-px u-h-64-px u-br-50'
          sx={{ background: '#D9D9D9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
        >
          {databaseError ? (
            <Image
              src={databaseSvg}
              alt='Database icon'
            />
          ) : (
            <Image
              src={chartSvg}
              alt='Chart icon'
            />
          )}
        </Box>
        <Typography className='fs-title-large u-fw-700'>Loading Error</Typography>
        <Typography className='fs-body-regular'>Oops, sorry, we seem to be having an issue on our end.</Typography>
      </Stack>
    </Card>
  );
};

export default LoadingError;
